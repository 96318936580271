
/**
 * Get MSAL Instance and Logging feature
 */

import {PublicClientApplication, LogLevel} from '@azure/msal-browser';
import packageJson from  '../../package.json'
import { _logException, _logInformation, _logEvent } from '../common/_logging.js';
import { decodeJwtToken, getNestedObject } from '../common/common.js';
import { store } from '..';

export default class MsalAuthProvider{
    
    msalInstance = null;
    msalConfig = null;
    
    getMsalInstance = () => {
        let is_safari = navigator.userAgent.indexOf("Safari") > -1
        let config = {
            auth: {
                clientId: store.getState().app.env.REACT_APP_AZURE_CLIENTID,
                authority: store.getState().app.env.REACT_APP_AZURE_AUTHORITY,
                postLogoutRedirectUri: window.location.origin,
                redirectUri: window.location.origin,
                navigateToLoginRequestUrl: true,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
                storeInMemory: true
            },
            system: {
                iframeHashTimeout: is_safari ? 10000 : 60000,
                windowHashTimeout: 60000,
                loadFrameTimeout:  500,
                loggerOptions: {
                    logLevel: LogLevel.Verbose,
                    loggerCallback: this.msalLogger,
                    piiLoggingEnabled: true
                },
                telemetry: {
                    applicationName: 'Rockefeller Account Opening',
                    applicationVersion: packageJson.version
                },
                tokenRenewalOffsetSeconds: 300
            }

        }

        this.msalConfig = config;
        this.msalInstance = new PublicClientApplication(config);

        return this.msalInstance;
    }

    msalLogger = (level, message) => {

        switch(level) {
    
            case LogLevel.Error: {
               console.error(message);
               _logException(message);
               break;
            }
    
           case LogLevel.Warning: {
               console.warn(message);
               _logInformation(message);
               break;
           }
    
           default: {
                console.log(message);
                _logInformation(message);
                break;
           }
        }
    }


    interactionBasedLogin = () => {
        let prompt = "select_account";
        _logEvent("1. User Logging in");
        const tokenRequest = {
          ...apiTokenScope(),
          prompt,
          forceRefresh: true,
        };
        this.msalInstance.acquireTokenRedirect(tokenRequest);
    };
    
    getTokenSilent = (account) => {
        const tokenRequest = {
          account,
          ...apiTokenScope(),
          forceRefresh: true,
        };
        return this.msalInstance.acquireTokenSilent(tokenRequest);
    };
    
    ssoLogin = (loginHint) => {
        const ssoRequest = {
          ...apiTokenScope(),
          loginHint,
          forceRefresh: true,
        };
        return this.msalInstance.ssoSilent(ssoRequest);
    };

    getAllAccounts = () => {
        return this.msalInstance.getAllAccounts();
    }
}

export const apiTokenScope = () => {
    return  {
        scopes: [store.getState().app.env.REACT_APP_AZURE_APISCOPE]
    }
}

export const getLoggedInUser = (token) => {
    let user = { name: '', userName: '', preferred_username: ''}


    let parsedToken = decodeJwtToken(token);
        if (parsedToken !== null) {
            user.name = getNestedObject(parsedToken, ['name']);
            user.userName = getNestedObject(parsedToken, ['email']);
            user.preferred_username = getNestedObject(parsedToken, ['preferred_username'])    
        }

    return user;
}

export const isTokenRefreshRequired = (token, intervalTime) => {
    let isRefreshRequired = false;

    if(token){
        let parsedToken = decodeJwtToken(token);
        if (parsedToken !== null) {
            let issuedTime = getNestedObject(parsedToken, ['iat']);
            let expirationTime = getNestedObject(parsedToken, ['exp']);
            let currentTime = new Date().valueOf() / 1000;
            console.log(`Issued Time:: ${issuedTime} Expiration Time:: ${expirationTime} Current Time:: ${currentTime}`);

            if (expirationTime <= currentTime + intervalTime / 1000) {
                isRefreshRequired = true;
            }
        }
    }

    return isRefreshRequired;
}
