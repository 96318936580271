import { store } from "..";

let baseUrl = window.location.origin;

export const apiBaseUrl = () => {
    return store.getState().app.env.REACT_APP_API_BASEURL;
}

export const getFinancialAdvisorDetailsUrl = `${baseUrl}/api/advisors/financialadvisor`;
export const insertIncomingAccountsUrl = `${baseUrl}/api/IncomingAccounts/Insert`;
export const updateIncomingAccountsUrl = `${baseUrl}/api/IncomingAccounts/Update`;
export const deleteIncomingAccountsUrl = `${baseUrl}/api/IncomingAccounts/Delete`;
export const updateIncomingRockAccountUrl = `${baseUrl}/api/IncomingAccounts/UpdateRockAcct`
export const getFinancialAdvisorsAccountUrl =`${baseUrl}/api/advisors/accounts`;
export const mapHouseholdUrl = `${baseUrl}/api/Household/MapHousehold`;
export const getHouseholdAcctsUrl = `${baseUrl}/api/Household/householdaccounts`;
export const uploadDocumentUrl = `/acctopngapi/api/AccountProfile/PdfUpload`;
export const documentUnoProcessUrl = `/acctopngapi/api/AccountProfile/Pdf2Split`;
export const updateWorkItemIdsUrl = `${baseUrl}/api/workitem/update`;
export const getEnvironmentVariableUrl = `${baseUrl}/api/EnvironmentConfig/Environment`;
export const uploadDocumentFromCtrlUrl = `${baseUrl}/api/Documents/upload`;
export const updateDocumentUnoSubmitterInfoUrl = `${baseUrl}/api/Documents/updateStartDocProcessSubmitterInfo`;
export const getUploadedDocumentByAccounturl = `${baseUrl}/api/AccountDocuments/uploadedDocumentsList`;
export const getDocTypeDescurl = `${baseUrl}/api/AccountDocuments/docTypeDescList`;
export const deletedocumenturl = `${baseUrl}/api/AccountDocuments/Delete`;
export const downloadDocumentUrl = `${baseUrl}/api/Documents/download`;
export const insertdocumenturl = `${baseUrl}/api/AccountDocuments/upload`;
export const updateSubmittedByEmailUrl = `${baseUrl}/api/Household/UpdatesubmittedBy`;
export const includeExcludeUpdateDocument = `${baseUrl}/api/AccountDocuments/IncludeExcludeUpdateDocument`;
export const startWorkItemWorkFlowUrl = `/acctopngapi/api/BPM/Upload`;
export const initiateToAUrl = `/acctopngapi/api/TOA/TransferOfAssetSubmit`;
export const financialAdvisorsFeatureUrl = `${baseUrl}/api/pilotfeature/list`;
export const getAllAccountsForAllAdvisors = `${baseUrl}/api/Reports/accounts`;
export const getAllAccountsSummary = `${baseUrl}/api/Reports/accountsummary`;
export const cloneAccountUrl= `/acctopngapi/api/AccountProfile/AcctCloning`;
export const getProposalListUrl = `/acctopngapi/api/AccountService/GetProposals`;
export const getProposalDetailsUrl = `/acctopngapi/api/AccountService/ProposalDetail`
export const saveProposalDetailUrl = `/acctopngapi/api/AccountService/ProposalDetail`
export const createAndSendDocusignUrl = `/acctopngapi/api/AccountService/CreateDocuSignEnvelope?proposalId=`
export const downloadDocumentsUrl = `/acctopngapi/api/AccountService/DownloadProposalDocuments`;
export const getAdvisorHandlesUrl = `/acctopngapi/api/AccountService/GetAdvisors`;





